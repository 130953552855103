<template>
    <div style="width: 100%; overflow: hidden; position: relative;" id="parent">
        <div class="left_tab_parent">
            <div class="left_tab">
                <div
                        v-for="(item, index) in tab_list"
                        :key="index"
                        :class="{ isChoose: isChoose == index }"
                        @click="showTab(index)"
                >
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="right_content_parent">
            <div class="right_content">
                <div
                        v-show="nowIndex == 1"
                        style="height: auto;padding-bottom: 20px;"
                >
                    <div style="height: 70px;display: flex;align-items: center;margin: 20px auto 30px;width:854px">
                        <Steps :current="current">
                            <Step title="填写基本信息"></Step>
                            <Step title="填写企业知识产权状况"></Step>
                            <Step title="确认企业财务及研发管理状况"></Step>
                            <Step title="提交生成测评报告"></Step>
                        </Steps>
                    </div>
                    <div v-show="current == 0">
                        <div class="step-con">
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        企业统一信用代码
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                                v-model="baseForm.creditCode"
                                                style="height: 50px"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        企业注册时间
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <DatePicker
                                                @on-change="getRegisterTime"
                                                :transfer="true"
                                                style="height: 90%!important;"
                                                ref="startTime"
                                                type="date"
                                                v-model="baseForm.registerTime"
                                        ></DatePicker>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        联系人
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                                v-model="baseForm.contactUser"
                                                style="height: 50px"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        联系方式
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                                v-model="baseForm.contactTel"
                                                placeholder='请输入联系方式'
                                                style="height: 50px"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        职工总数
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                                v-model="baseForm.staffTotal"
                                                placeholder='请输入职工总数'
                                                style="width:160px !important"
                                        ></Input>
                                        <span style="margin: 0 10px">
                        其中科技人员数
                      </span>
                                        <Input
                                                v-model="baseForm.researcherNumber"
                                                placeholder='请输入科技人员数'
                                                style="width:160px !important"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        企业所在省市
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Select v-model="baseForm.provinceCode" :transfer="transfer"
                                                style="width: 212px!important;"
                                                @on-change="getNewProvinceT">
                                            <Option :key="index" v-for="(value,key,index) in provinceData" :value="key">
                                                {{ value }}
                                            </Option>
                                        </Select>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Select v-model="baseForm.cityCode" :transfer="transfer"
                                                style="width: 212px!important;"
                                                @on-change="getCity">
                                            <Option :key="index" v-for="(value,key,index) in cityData" :value="key">
                                               {{ value }}
                                            </Option>
                                        </Select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        企业地址
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                                v-model="baseForm.detailAddress"
                                                style="height: 50px"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        执政处罚情况
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <RadioGroup v-model="baseForm.punishment">
                                            <Radio label="1"><span>有</span></Radio>
                                            <Radio label="0"><span>无</span></Radio>
                                        </RadioGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        主要产品技术领域
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Select v-model="baseForm.mptCode" :transfer="transfer" style="width:439px">
                                            <Option
                                                    v-for="(item, index) in technologyList"
                                                    :key="index"
                                                    :value="item.value"
                                            >{{ item.name }}
                                            </Option>
                                        </Select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        产品获奖情况
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Select v-model="baseForm.awards" :transfer="transfer" style="width:439px">
                                            <Option value="1">重点新产品</Option>
                                            <Option value="2">高新技术产品</Option>
                                            <Option value="3">名牌产品</Option>
                                            <Option value="4">其他</Option>
                                        </Select>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div
                                style="border-top: none; margin-top: 30px"
                                class="next_style common_liucheng;"
                        >
                            <div
                                    style="height: 40px;line-height: 40px; width: 120px"
                                    class="button_style"
                                    @click="firstNext()"
                            >
                                下一步
                            </div>
                            <!--              <div-->
                            <!--                  style="-->
                            <!--                  height: 40px;-->
                            <!--                  width: 120px;-->
                            <!--                  background-color: #fc7c40;-->
                            <!--                  margin-left: 20px;-->
                            <!--                "-->
                            <!--                  class="button_style"-->
                            <!--                  @click="confirmBaseBu()"-->
                            <!--              >-->
                            <!--                暂存-->
                            <!--              </div>-->
                        </div>
                    </div>

                    <div class="page-1" v-show="current == 1">
                        <div class="step-con">
                            <div class="step-title">| 类知识产权</div>
                            <div class="step-bg">
                                <div v-for="(item, index) in ipList1" :key="index" class="first">
                                    <Row>
                                        <Col span="8" style="font-weight:bold">
                                            <div v-if="index == 0" class="table_div">
                                                <span>发明专利</span>
                                            </div>
                                            <div v-if="index == 1" class="table_div">
                                                <span>植物新品种</span>
                                            </div>
                                            <div v-if="index == 2" class="table_div">
                                                <span>国家农作物品种</span>
                                            </div>
                                            <div v-if="index == 3" class="table_div">
                                                <span>国家新药</span>
                                            </div>
                                            <div v-if="index == 4" class="table_div">
                                                <span>国际一级中药保护品种</span>
                                            </div>
                                            <div v-if="index == 5" class="table_div">
                                                <span>集成电路布局设计专有权</span>
                                            </div>
                                        </Col>
                                        <Col span="16">
                                            <div class="table_div2">
                                                <div class="table_div">
                                                    <span>自主研发获取</span>
                                                </div>
                                            </div>
                                            <div class="table_div2">
                                                <Input
                                                        v-model="item.selfDevNumber"
                                                ></Input>
                                            </div>
                                            <div class="table_div2">
                                                <div class="table_div" style="margin-left: 20px">
                                                    <span>受让、受赠和并购获取</span>
                                                </div>
                                            </div>
                                            <div class="table_div2">
                                                <Input
                                                        v-model="item.traGiftMergerNumber"
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <div class="step-con">
                            <div class="step-title">|| 类知识产权</div>
                            <div class="step-bg">
                                <div v-for="(item, index) of ipList2" :key="index">
                                    <Row>
                                        <Col span="8" style="font-weight:bold">
                                            <div v-if="index == 0" class="table_div">
                                                <span>实用新型专利</span>
                                            </div>
                                            <div v-if="index == 1" class="table_div">
                                                <span>软件著作权</span>
                                            </div>
                                        </Col>
                                        <Col span="16">
                                            <div class="table_div2">
                                                <div class="table_div">
                                                    <span>自主研发获取</span>
                                                </div>
                                            </div>
                                            <div class="table_div2">
                                                <Input
                                                        v-model="item.selfDevNumber"
                                                ></Input>
                                            </div>
                                            <div class="table_div2">
                                                <div class="table_div" style="margin-left: 20px">
                                                    <span>受让、受赠和并购获取</span>
                                                </div>
                                            </div>
                                            <div class="table_div2">
                                                <Input
                                                        v-model="item.traGiftMergerNumber"
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        <div class="step-con">
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        与主要产品或服务相关的知识产权数量
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input v-model="relatedNumber"></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        近三年科技成果转化数量
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                                v-model="threeYearIpTotal"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        组织参与制定标准、检测方法、技术规范的情况
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <RadioGroup v-model="orgParticipation">
                                            <Radio label="1"><span>有</span></Radio>
                                            <Radio label="0"><span>无</span></Radio>
                                        </RadioGroup>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style="margin-top: 30px"
                             class="next_style common_liucheng;">
                            <div style="height: 40px;line-height: 40px; width: 120px" class="button_style" @click="current = 0">
                                上一步
                            </div>
                            <div style="height: 40px;line-height: 40px; width: 120px; margin-left: 20px" class="button_style"
                                 @click="secondNext()">
                                下一步
                            </div>
                            <!--              <div-->
                            <!--                  style="-->
                            <!--                  height: 40px;-->
                            <!--                  width: 120px;-->
                            <!--                  background-color: #fc7c40;-->
                            <!--                  margin-left: 20px;-->
                            <!--                "-->
                            <!--                  class="button_style"-->
                            <!--                  @click="confirmIpBu()"-->
                            <!--              >-->
                            <!--                暂存-->
                            <!--              </div>-->
                        </div>
                    </div>
                    <div v-show="current == 2">
                        <div class="step-con">
                            <div class="step-title">年度财务状况</div>
                            <div class="step-bg">
                                <Row>
                                    <Col span="6" style="font-weight:bold">
                                        <div class="table_div">
                                            <span>{{ this.thisYear - 1 }}年财务状况</span>
                                        </div>
                                    </Col>
                                    <Col span="18">
                                        <div class="table_div2">
                                            <div class="table_div">
                                                <span>年度销售收入</span>
                                            </div>
                                        </div>
                                        <div class="table_div2" style="margin-right: 19px">
                                            <Input
                                                    v-model="financeFrom.last1stYearSaleRevenue"
                                                    style="width:100px"
                                            />
                                        </div>
                                        <div class="table_div2">
                                            <div class="table_div">
                                                <span>净资产总额</span>
                                            </div>
                                        </div>
                                        <div class="table_div2" style="margin-right: 19px">
                                            <Input
                                                    v-model="financeFrom.last1stYearNetAssetsTotal"
                                                    style="width:100px"
                                            />
                                        </div>
                                        <div class="table_div2">
                                            <div class="table_div">
                                                <span>年度研发投入总额</span>
                                            </div>
                                        </div>
                                        <div class="table_div2">
                                            <Input
                                                    v-model="financeFrom.last1stYearRdInvestmentTotal"
                                                    style="width:100px"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="6" style="font-weight:bold">
                                        <div class="table_div">
                                            <span>{{ this.thisYear - 2 }}年财务状况</span>
                                        </div>
                                    </Col>
                                    <Col span="18">
                                        <div class="table_div2">
                                            <div class="table_div">
                                                <span>年度销售收入</span>
                                            </div>
                                        </div>
                                        <div class="table_div2" style="margin-right: 19px">
                                            <Input
                                                    v-model="financeFrom.last2ndYearSaleRevenue"
                                                    style="width:100px"
                                            />
                                        </div>
                                        <div class="table_div2">
                                            <div class="table_div">
                                                <span>净资产总额</span>
                                            </div>
                                        </div>
                                        <div class="table_div2" style="margin-right: 19px">
                                            <Input
                                                    v-model="financeFrom.last2ndYearNetAssetsTotal"
                                                    style="width:100px"
                                            />
                                        </div>
                                        <div class="table_div2">
                                            <div class="table_div">
                                                <span>年度研发投入总额</span>
                                            </div>
                                        </div>
                                        <div class="table_div2">
                                            <Input
                                                    v-model="financeFrom.last2ndYearRdInvestmentTotal"
                                                    style="width:100px"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="6" style="font-weight:bold">
                                        <div class="table_div">
                                            <span>{{ this.thisYear - 3 }}年财务状况</span>
                                        </div>
                                    </Col>
                                    <Col span="18">
                                        <div class="table_div2">
                                            <div class="table_div">
                                                <span>年度销售收入</span>
                                            </div>
                                        </div>
                                        <div class="table_div2" style="margin-right: 19px">
                                            <Input
                                                    v-model="financeFrom.last3rdYearSaleRevenue"
                                                    style="width:100px"
                                            />
                                        </div>
                                        <div class="table_div2">
                                            <div class="table_div">
                                                <span>净资产总额</span>
                                            </div>
                                        </div>
                                        <div class="table_div2" style="margin-right: 19px">
                                            <Input
                                                    v-model="financeFrom.last3rdYearNetAssetsTotal"
                                                    style="width:100px"
                                            />
                                        </div>
                                        <div class="table_div2">
                                            <div class="table_div">
                                                <span>年度研发投入总额</span>
                                            </div>
                                        </div>
                                        <div class="table_div2">
                                            <Input
                                                    v-model="financeFrom.last3rdYearRdInvestmentTotal"
                                                    style="width:100px"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <div style="width:33.5%">
                                        <div class="table_div">
                                            <span>前三年度累计研发占比</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="table_div">
                                            <Select
                                                    v-model="financeFrom.lastYearHighTechRate"
                                                    style="width: 466px !important"
                                                    :transfer="transfer"
                                            >
                                                <Option value="A"
                                                >累计销售收入≤5000万，研发累计≥5%
                                                </Option
                                                >
                                                <Option value="B"
                                                >5000万＜累计销售收入≤2亿，研发费用累计≥4%
                                                </Option
                                                >
                                                <Option value="C"
                                                >累计销售收入＞2亿，研发费用累计≥3%
                                                </Option
                                                >
                                                <Option value="D">其他</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <div style="width:33.5%">
                                        <div class="table_div">
                                            <span>前三年度累计境内研发费用占总研发费用比</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="table_div">
                                            <Select
                                                    v-model="financeFrom.threeYearDomesticRdRate"
                                                    style="width: 466px !important"
                                                    :transfer="transfer"
                                            >
                                                <Option value="A"> >60%</Option>
                                                <Option value="B"> <60%</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <div style="width:33.5%">
                                        <div class="table_div">
                                            <span>上一年度高新技术产品（服务）收入占比</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="table_div">
                                            <Select
                                                    v-model="financeFrom.threeYearRdRate"
                                                    style="width:466px !important"
                                                    :transfer="transfer"
                                            >
                                                <Option value="A"> >60%</Option>
                                                <Option value="B"> <60%</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </div>

                        <div class="step-con">
                            <div class="step-title">其他状况</div>
                            <div class="step-bg">
                                <Row>
                                    <Col span="6">
                                        <div class="table_div" style="font-size:14px">
                                            <span>研发管理制度建设情况</span>
                                        </div>
                                    </Col>
                                    <Col span="18">
                                        <div class="table_div" style="justify-content:start;color:#666666;">
                                            <CheckboxGroup v-model="fourthForm.rdManage"
                                                           @on-change="delAll('rdManage')">
                                                <Checkbox label="A">
                                                    已制定企业研究开发的组织管理制度
                                                </Checkbox>
                                                <Checkbox label="B">
                                                    已经建立了研发投入核算体系
                                                </Checkbox>
                                                <Checkbox label="C">
                                                    已经编制了研发费用辅助账
                                                </Checkbox>
                                                <Checkbox label="D">
                                                    以上都没有
                                                </Checkbox>
                                            </CheckboxGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="6">
                                        <div class="table_div" style="font-size:14px">
                                            <span>自主研发及合作状况</span>
                                        </div>
                                    </Col>
                                    <Col span="18">
                                        <div class="table_div" style="justify-content:start;color:#666666;">
                                            <CheckboxGroup v-model="fourthForm.rdCooperation"
                                                           @on-change="delAll('rdCooperation')">
                                                <Checkbox label="A">
                                                    已自主设立研发机构
                                                </Checkbox>
                                                <Checkbox label="B">
                                                    与高校/科研院所开展产学研合作（具备签署技术开发协议并已支付委托经费）
                                                </Checkbox>
                                                <Checkbox label="C">
                                                    以上都没有
                                                </Checkbox>
                                            </CheckboxGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="6">
                                        <div class="table_div" style="font-size:14px">
                                            <span>成果转化激励及创新情况</span>
                                        </div>
                                    </Col>
                                    <Col span="18">
                                        <div class="table_div" style="justify-content:start;color:#666666;">
                                            <CheckboxGroup v-model="fourthForm.trainingIncentive"
                                                           @on-change="delAll('trainingIncentive')">
                                                <Checkbox label="A">
                                                    已建立科技成果转化的组织实施与激励奖励制度
                                                </Checkbox>
                                                <Checkbox label="B">
                                                    已具备开放式的创新创业平台
                                                </Checkbox>
                                                <Checkbox label="C">
                                                    以上都没有
                                                </Checkbox>
                                            </CheckboxGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="6">
                                        <div class="table_div" style="font-size:14px">
                                            <span>人才培养及激励方法情况</span>
                                        </div>
                                    </Col>
                                    <Col span="18">
                                        <div class="table_div" style="justify-content:start;color:#666666;">
                                            <CheckboxGroup v-model="fourthForm.excitationInnovate"
                                                           @on-change="delAll('excitationInnovate')">
                                                <Checkbox label="A">
                                                    已具备科技人员的培养进修、职工技能培训、优秀人才引进制度
                                                </Checkbox>
                                                <Checkbox label="B">
                                                    已具备科技人员评价奖励制度
                                                </Checkbox>
                                                <Checkbox label="C">
                                                    以上都没有
                                                </Checkbox>
                                            </CheckboxGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div
                                style="height: 180px; border-top: none; margin-top: 50px"
                                class="next_style common_liucheng;"
                        >
                            <div
                                    style="height: 40px; width: 120px"
                                    class="button_style"
                                    @click="current = 1"
                            >
                                上一步
                            </div>
                            <div
                                    style="height: 40px;line-height40px;width: 120px; margin-left: 20px"
                                    class="button_style"
                                    @click="confirmRdManageBt()"
                            >
                                提交
                            </div>
                            <!--              <div-->
                            <!--                  style="-->
                            <!--                  height: 40px;-->
                            <!--                  width: 120px;-->
                            <!--                  background-color: #fc7c40;-->
                            <!--                  margin-left: 20px;-->
                            <!--                "-->
                            <!--                  class="button_style"-->
                            <!--                  @click="confirmFan()"-->
                            <!--              >-->
                            <!--                暂存-->
                            <!--              </div>-->
                        </div>
                    </div>
                </div>

                <div v-show="nowIndex == 0" style="height: auto">
                    <div
                            style="
              display: flex;
              justify-content: center;
              height: 70px;
              line-height: 70px;
              background-color: #1767E0;
              border: 1px solid #1767E0;
              color: white;
              font-size: 20px;
            "
                    >
            <span>{{ reportList.enterpriseName }}</span
            >&nbsp;&nbsp;
                        <span style="margin-left: 20px"
                        >国家高新技术企业测评报告
              <span>({{ reportList.createTime }})</span></span
                        >
                    </div>
                    <div style="height:auto; border: 1px solid rgb(218, 226, 229)">
                        <div style="height: 370px; padding-top: 70px" class="div_center">
                            <Row :gutter="16">
                                <Col span="12">
                                    <div style="height: 220px">
                                        <div style="height: 44px; line-height: 44px">
                      <span
                              style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                          padding-right: 10px;
                        "
                      >企业基本情况得分</span
                      >
                                            <i-progress :percent="reportList.baseScore">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: #1767E0"
                                                >{{ reportList.baseScore }}分</span
                                                >
                                            </i-progress>
                                        </div>
                                        <div style="height: 44px; line-height: 44px">
                      <span
                              style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                          padding-right: 10px;
                        "
                      >企业加分项得分</span
                      >
                                            <i-progress :percent="reportList.additionalScore">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: #1767E0"
                                                >{{ reportList.additionalScore }}分</span
                                                >
                                            </i-progress>
                                        </div>
                                        <div style="height: 44px; line-height: 44px">
                      <span
                              style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                              padding-right: 10px;
                        "
                      >企业财务状况</span
                      >
                                            <i-progress :percent="reportList.financeScore">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: #1767E0"
                                                >{{ reportList.financeScore }}分</span
                                                >
                                            </i-progress>
                                        </div>
                                        <div style="height: 44px; line-height: 44px">
                      <span
                              style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                              padding-right: 10px;
                        "
                      >企业知识产权状况</span
                      >
                                            <i-progress :percent="reportList.ipScore">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: #1767E0"
                                                >{{ reportList.ipScore }}分</span
                                                >
                                            </i-progress>
                                        </div>
                                        <div style="height: 44px; line-height: 44px">
                      <span
                              style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                              padding-right: 10px;
                        "
                      >企业研发管理状况</span
                      >
                                            <i-progress :percent="reportList.manageScore">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: #1767E0"
                                                >{{ reportList.manageScore }}分</span
                                                >
                                            </i-progress>
                                        </div>
                                    </div>
                                </Col>
                                <Col span="12">
                                    <div
                                            style="
                      font-size: 14px;
                      height: 220px;
                      border: 1px solid rgb(205, 240, 243);
                      border-right: none;
                      border-bottom: none;
                    "
                                    >
                                        <Row>
                                            <Col span="18">
                                                <div class="table-box">企业测评总得分</div>
                                            </Col>
                                            <Col span="6">
                                                <div class="table-box-double">
                                                    {{ reportList.totalScore }}分
                                                </div>
                                            </Col>
                                            <Col span="18">
                                                <div class="table-box"
                                                     style="display: flex;justify-content: space-between;align-items: center">
                                                    <span>国家高新技术企业认证得分要求</span>
                                                    <!--                          <div-->
                                                    <!--                              v-if="data3.countryEval == 'N'"-->
                                                    <!--                              class="arrow_ld"-->
                                                    <!--                              style="background-color: orange"-->
                                                    <!--                          >-->
                                                    <!--                            <Icon type="md-close"/>-->
                                                    <!--                          </div>-->

                                                </div>
                                            </Col>
                                            <Col span="6">
                                                <div class="table-box-double" v-if="data3.countryLevel == '无'">
                                                    <div class="arrow_ld">
                                                        <Icon type="md-checkmark"/>
                                                    </div>
                                                </div>
                                                <div v-else class="table-box-double">
                                                    {{data3.countryLevel}}分
                                                </div>
                                            </Col>
                                            <Col span="18">
                                                <div class="table-box"
                                                     style="display: flex;justify-content: space-between;align-items: center">
                                                    <span>{{ reportList.provinceName }}高新技术企业入库标准得分要求</span>
                                                </div>
                                            </Col>
                                            <Col span="6">
                                                <div class="table-box-double" v-if="data3.provinceLevel == '无'">
                                                    <div class="arrow_ld">
                                                        <Icon type="md-checkmark"/>
                                                    </div>
                                                </div>
                                                <div v-else class="table-box-double">
                                                    {{data3.provinceLevel}}分
                                                </div>
                                            </Col>
                                            <Col span="18">
                                                <div class="table-box"
                                                     style="display: flex;justify-content: space-between;align-items: center">
                                                    <span>{{ reportList.provinceName }}{{ reportList.cityName }}高新技术企业入库标准得分要求</span>
                                                </div>
                                            </Col>
                                            <Col span="6">
                                                <div class="table-box-double" v-if="data3.cityLevel == '无'">
                                                    <div class="arrow_ld">
                                                        <Icon type="md-checkmark"/>
                                                    </div>
                                                </div>
                                                <div v-else class="table-box-double">
                                                    {{data3.cityLevel}}分
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style="height: 400px; padding-top: 50px" class="div_center">
                            <div
                                    style="
                  font-size: 14px;
                  height: 300px;
                  border: 1px solid rgb(205, 240, 243);
                  border-right: none;
                  border-bottom: none;
                "
                            >
                                <Row>
                                    <Col span="6">
                                        <div
                                                class="table-box"
                                                style="
                        height: 50px;
                        text-align: center;
                        background-color: #1767E0;
                        color: white;

                      "
                                                title="国家高新技术企业认证基本条件达成情况"
                                        >
                                            国家高新技术企业认证基本条件达成情况
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            国家高新技术企业认证标准
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{ reportList.provinceName }}国高企入库标准
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{ reportList.cityName }}国高企入库标准
                                        </div>
                                    </Col>
                                </Row>
                                <Row v-for="(item,index) in this.data2" :key="index">
                                    <Col span="6">
                                        <div
                                                class="table-box"
                                                style="height: 50px; text-align: center"
                                                :title="item.itemCondition"
                                        >
                                            {{ item.itemCondition }}
                                        </div>
                                    </Col>
                                    <Col span="3">
                                        <div class="table-box-double" style="height: 50px" :title="item.countryLevel">
                                            {{ item.countryLevel }}
                                        </div>
                                    </Col>
                                    <Col span="3">
                                        <div class="table-box-double" style="height: 50px">
                                            <div
                                                    v-if="item.countryEval == 'N'"
                                                    class="arrow_ld"
                                                    style="background-color: orange"
                                            >
                                                <Icon type="md-close"/>
                                            </div>
                                            <div v-else class="arrow_ld">
                                                <Icon type="md-checkmark"/>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span="3">
                                        <div class="table-box-double" style="height: 50px" :title="item.provinceLevel">
                                            {{ item.provinceLevel }}
                                        </div>
                                    </Col>
                                    <Col span="3">
                                        <div class="table-box-double" style="height: 50px">
                                            <div
                                                    v-if="item.provinceEval == 'N'"
                                                    class="arrow_ld"
                                                    style="background-color: orange"
                                            >
                                                <Icon type="md-close"/>
                                            </div>
                                            <div v-else class="arrow_ld">
                                                <Icon type="md-checkmark"/>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span="3">
                                        <div class="table-box-double" style="height: 50px" :title="item.cityLevel"> {{
                                            item.cityLevel }}
                                        </div>
                                    </Col>
                                    <Col span="3">
                                        <div class="table-box-double" style="height: 50px">
                                            <div
                                                    v-if="item.cityEval == 'N'"
                                                    class="arrow_ld"
                                                    style="background-color: orange"
                                            >
                                                <Icon type="md-close"/>
                                            </div>
                                            <div v-else class="arrow_ld">
                                                <Icon type="md-checkmark"/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        </div>
                        <div
                                style="height: auto; border-bottom: none; padding-top: 50px"
                                class="div_center"
                        >
                            <div
                                    style="
                  font-size: 14px;
                  height: auto;
                  border: 1px solid rgb(205, 240, 243);
                  border-right: none;
                  border-bottom: none;
                "
                            >
                                <Table
                                        border
                                        :columns="columns1"
                                        :data="data1"
                                        :disabled-hover="true"
                                        id="origin"
                                ></Table>
                            </div>
                        </div>
                        <div
                                style="height: 180px; border-top: none"
                                class="next_style common_liucheng;"
                        >
                            <div style="height: 40px; width: 120px;display: none" class="button_style">
                                下载报告
                            </div>
                            <div
                                    @click="reTest()"
                                    style="
                  height: 40px;
                  width: 120px;
                  margin-left: 20px;
                  background-color: #1767E0;
                "
                                    class="button_style"
                            >
                                重新测评
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="enterprise-footer" style="text-align: center">
            <a style="color: #515a6e" href="https://beian.miit.gov.cn/" target="_blank">连云港科技大市场版权所有2013-2024
                苏ICP备18024573号-2. All Rights Reserved.</a>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";
    import {
        getTechnologyField,
        confirmBase,
        confirmIp,
        confirmFinance,
        confirmRdManage,
        getReport,
        getEnterpriseInfo,
        getNewProvince,
        getByParentCode,
        getEvalId,
        getBaseInfo,
        getSecondInfo,
        getThirdInfo,
        getFourthInfo
    } from "@/plugins/api/EnterpriseExam";
    import {
        isCorrectPassword,
        isPhoneNumber,
        successToast,
        isNumber,
        warnToast,
        errorToast,
    } from "@/plugins/tools/util";


    export default {
        name: "EnterprisePhysicalExamination",
        mounted() {
            this.ReportIfExist()

        },
        data() {
            return {
                oldEvalId: "",
                cityData: {},
                provinceData: {"": ''},
                thisYear: "",
                evalId: "",
                transfer: true,
                secondFlag: false,
                thirdFlag: false,
                // 第一步from
                baseForm: {
                    awards: "",
                    contactTel: "",
                    contactUser: "",
                    creditCode: "",
                    detailAddress: "",
                    mptCode: "",
                    punishment: "",
                    registerTime: "",
                    researcherNumber: "",
                    staffTotal: "",
                },

                // 第二步form
                ipList1: [
                    {
                        ipClass: 1,
                        ipItemCode: "FMZL",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "ZWXPZ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "NZWPZ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "GJXY",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "BHPZ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "SJZYQ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                ],
                orgParticipation: 1,
                threeYearIpTotal: 0,
                relatedNumber: 0,
                ipList2: [
                    {
                        ipClass: 2,
                        ipItemCode: "SYXXZL",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 2,
                        ipItemCode: "RJZZQ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                ],
                data3: [],
                reportList: [], //报告内容
                //第三步form
                financeFrom: {
                    evalId: "",
                    last1stYearNetAssetsTotal: 0,
                    last1stYearRdInvestmentTotal: 0,
                    last1stYearSaleRevenue: 0,
                    last2ndYearNetAssetsTotal: 0,
                    last2ndYearRdInvestmentTotal: 0,
                    last2ndYearSaleRevenue: 0,
                    last3rdYearNetAssetsTotal: 0,
                    last3rdYearRdInvestmentTotal: 0,
                    last3rdYearSaleRevenue: 0,
                    lastYearHighTechRate: "A",
                    threeYearDomesticRdRate: "B",
                    threeYearRdRate: "A",
                },

                // 四form
                fourthForm: {
                    evalId: "22",
                    excitationInnovate: [],
                    rdCooperation: [],
                    rdManage: [],
                    trainingIncentive: [],
                },
                technologyList: [],
                nowIndex: 1,
                has: "有",
                nohas: false,
                hjqk: "",
                jsly: "",
                sciencePeople: [],
                current: 0,
                tab_height: 0,
                tab_list: [
                    {name: "国家高新技术企业体检", path: "enterprise-physical-examination"},
                    {name: "科技型中小企业测评", path: "enterprise-science-examination"},
                    {name: "软件企业测评", path: "enterprise-soft-examination"}
                ],
                isChoose: 0,
                contentIndex: 0,
                data2: [],
                keyword: "",
                columns1: [
                    {
                        title: "国家高新技术企业认证具体得分情况",
                        key: "itemCondition",
                        width: "600px",
                        className: "demo-table-info-column",
                    },
                    {
                        title: "国家高新技术企业认证标准",
                        key: "countryEval",
                    },
                    {
                        title: "江苏省国企入库标准",
                        key: "provinceEval",
                    },
                    {
                        title: "南京市国高企入库标准",
                        key: "cityEval",
                    },
                ],
                IP_ITEM_List: [],
                BASE_INF_List: [],
                data1: [],
            };
        },
        methods: {

            delAll(name) {
                if (name != 'rdManage') {
                    if (this.fourthForm[name].length >= 2 && this.fourthForm[name].indexOf("C") > -1) {
                        this.fourthForm[name] = ['C']
                    }
                } else if (name == 'rdManage') {
                    if (this.fourthForm[name].length >= 2 && this.fourthForm[name].indexOf("D") > -1) {
                        this.fourthForm[name] = ['D']
                    }

                }

            },
            getRegisterTime(starTime) {
                this.baseForm.registerTime = starTime;
            },
            // 是否有报告
            async ReportIfExist() {
                let json = await getEvalId();
                if (json.code == 200) {
                    if (json.data) {
                        this.oldEvalId = json.data
                        this.getReportFun();
                    } else {
                        this.changeHeight();
                        getEnterpriseInfo().then((res) => {
                            if (res.code == 200) {
                                this.baseForm.contactTel = res.data.contactTel;
                                this.baseForm.staffTotal = res.data.staffTotal;
                                this.baseForm.detailAddress = res.data.site;
                                this.baseForm.registerTime = new Date(res.data.registerTime)
                                this.baseForm.contactUser = res.data.legalRepresentative;
                                this.baseForm.creditCode = res.data.creditCode;
                                this.baseForm.enterpriseName = res.data.enterpriseName;
                                this.baseForm.provinceName = "";
                                this.baseForm.cityName = "";
                                this.baseForm.provinceCode = "";
                                this.baseForm.cityCode = "";
                                this.baseForm.punishment = 1
                            }
                        });
                        getNewProvince().then((res) => {
                            if (res.code == 200) {
                                this.provinceData = res.data
                            }
                        })
                        this.getTechnology();
                        let date = new Date();
                        this.thisYear = date.getFullYear();
                    }

                }
            },
            // 得到报告
            async getReportFun() {
                let data = {
                    evalId: this.evalId == "" ? this.oldEvalId : this.evalId,
                };
                let json = await getReport(data);
                if (json.code == "200") {
                    this.reportList = json.data;
                    this.columns1[2].title = this.reportList.provinceName + '国企入库标准';
                    this.columns1[3].title = this.columns1[3].title = this.reportList.cityName + '国企入库标准'
                    let dateee2 = new Date(this.reportList.createTime).toJSON();
                    this.reportList.createTime = new Date(
                        +new Date(dateee2) + 8 * 3600 * 1000
                    )
                        .toISOString()
                        .replace(/T/g, " ")
                        .replace(/\.[\d]{3}Z/, "")
                        .split(" ")[0];
                    this.data1 = this.reportList.itemList;
                    this.data2 = this.reportList.baseList;
                    this.data3 = this.reportList.headList;
                    if (!this.data3['provinceLevel']) {
                        this.data3['provinceLevel'] = '无'
                    }
                    if (!this.data3['countryLevel']) {
                        this.data3['countryLevel'] = '无'
                    }
                    if (!this.data3['cityLevel']) {
                        this.data3['cityLevel'] = '无'
                    }

                    for (let i = 0; i < this.data2.length; i++) {
                        if (!this.data2[i]['provinceLevel']) {
                            this.data2[i]['provinceLevel'] = '无'
                        }
                        if (!this.data2[i]['countryLevel']) {
                            this.data2[i]['countryLevel'] = '无'
                        }
                        if (!this.data2[i]['cityLevel']) {
                            this.data2[i]['cityLevel'] = '无'
                        }
                    }
                    this.data1.forEach((item, index) => {
                        if (!item['countryEval']) {
                            item['countryEval'] = '0分'
                        } else {
                            item['countryEval'] += '分'
                        }
                        if (!item['provinceEval']) {
                            item['provinceEval'] = '0分'
                        } else {
                            item['provinceEval'] += '分'
                        }
                        if (!item['cityEval']) {
                            item['cityEval'] = '0分'
                        } else {
                            item['cityEval'] += '分'
                        }

                    })
                    this.nowIndex = 0;
                    this.changeHeight();
                    this.$nextTick(() => {
                        for (let i = 0; i < this.ipList2.length; i++) {
                            this.ipList2[i].selfDevNumber = 0;
                            this.ipList2[i].traGiftMergerNumber = 0
                        }
                        for (let i = 0; i < this.ipList1.length; i++) {
                            this.ipList1[i].selfDevNumber = 0;
                            this.ipList1[i].traGiftMergerNumber = 0
                        }


                        for (let item in this.fourthForm) {
                            if (item == 'evalId') {
                                this.fourthForm[item] = "";
                            } else {
                                this.fourthForm[item] = [];
                            }
                        }
                    })

                } else {
                    this.nowIndex = 1;
                    this.changeHeight();
                    this.getTechnology();
                    let date = new Date();
                    this.thisYear = date.getFullYear();
                    let rjson = await getBaseInfo({evalId: this.oldEvalId});
                    if (rjson.code == 200) {
                        for (let item in this.baseForm) {

                            if (item == 'registerTime') {
                                this.baseForm.registerTime = new Date(rjson.data[item]);
                            } else {
                                this.baseForm[item] = rjson.data[item] + "";
                            }
                        }
                        this.baseForm.provinceCode = rjson.data['provinceCode'] + ""
                        this.baseForm.cityCode = rjson.data['cityCode'] + ''
                        getNewProvince().then((res) => {
                            if (res.code == 200) {
                                this.provinceData = res.data
                                if (this.baseForm.provinceCode) {
                                    this.getNewProvinceT(this.baseForm.provinceCode)
                                }
                            }
                        })

                        // this.getProvinceT(this.baseForm.provinceCode)
                        this.nowIndex = 1;
                        this.changeHeight();
                        document.body.scrollTop = 0
                        document.documentElement.scrollTop = 0
                    }
                }
            },
            async firstNext() {
                this.baseForm.id = this.oldEvalId;
                console.log(this.baseForm)
                for (let item in this.baseForm) {
                    if (item !== "detailAddress" || item !== "awards") {
                        if (this.baseForm[item] === "" && item != 'id') {
                            warnToast("请将数据填写完成");
                            return;
                        }
                    }
                }
                if (isNumber(this.baseForm.staffTotal) == false) {
                    warnToast("职工人数请输入数字")
                    return
                }
                ;
                if (isNumber(this.baseForm.researcherNumber) == false) {
                    warnToast("科技员工人数请输入数字")
                    return
                }
                ;


                let json = await confirmBase(this.baseForm);
                if (json.code == "200") {
                    this.evalId = json.data.evalId;
                    this.current = 1;
                    if (this.oldEvalId != "") {
                        getSecondInfo({
                            evalId: this.oldEvalId
                        }).then(res => {
                            if (res.code == 200) {
                                if (JSON.stringify(res.data) != '{}') {
                                    this.ipList1 = res.data.ipList.slice(0, 6)
                                    this.ipList2 = res.data.ipList.slice(6, res.data.ipList.length);
                                    this.relatedNumber = res.data.relatedNumber
                                    this.threeYearIpTotal = res.data.threeYearIpTotal
                                    this.orgParticipation = res.data.orgParticipation + ""
                                }

                            }
                        })
                    }
                    this.changeHeight();
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                } else {
                    errorToast(json.message)
                }


            },

            async secondNext() {
                let data = {
                    id: this.oldEvalId,
                    evalId: this.evalId,
                    relatedNumber: this.relatedNumber,
                    threeYearIpTotal: this.threeYearIpTotal,
                    orgParticipation: this.orgParticipation,
                    ipList: this.ipList1.concat(this.ipList2),
                };
                if (isNumber(this.relatedNumber) == false) {
                    warnToast("近三年科技成果转化数量该项请输入数字")
                    return ""
                }
                if (isNumber(this.threeYearIpTotal) == false) {
                    warnToast("与主要产品或服务相关的知识产权数量 该项请输入数字")
                    return ""
                }

                for (let i = 0; i < data.ipList.length; i++) {
                    if (isNumber(data.ipList[i].selfDevNumber) == false || isNumber(isNumber(data.ipList[i].traGiftMergerNumber) == false)) {
                        warnToast("请输入正整数")
                        return ''
                    }
                }
                let json = await confirmIp(data);

                if (json.code == "200") {
                    this.current = 2;
                    if (this.oldEvalId != "") {
                        getThirdInfo({evalId: this.oldEvalId}).then(res => {
                            if (res.code == 200) {
                                if (JSON.stringify(res.data) != '{}') {
                                    this.financeFrom = res.data
                                }

                            }
                        });
                    }

                    this.changeHeight();
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                } else {
                    warnToast(json.message)
                }


            },
            async confirmRdManageBt() {
                this.financeFrom.evalId = this.evalId;
                let json = await confirmFinance(this.financeFrom);
                if (json.code == "200") {
                    this.fourthForm.evalId = this.evalId;
                    let submitData = JSON.parse(JSON.stringify(this.fourthForm));
                    for (let item in submitData) {
                        if (!submitData[item]) {
                            warnToast("请将数据填写完整");
                            return;
                        }
                        if (typeof submitData[item] == 'object') {
                            submitData[item] = submitData[item].join(',')
                        }
                    }

                    submitData.evalId = this.evalId;
                    submitData["evalStatus"] = 1;
                    let json = await confirmRdManage(submitData);
                    if (json.code == "200") {
                        this.getReportFun();
                        this.nowIndex = 0;
                        document.body.scrollTop = 0
                        document.documentElement.scrollTop = 0;
                        this.changeHeight();
                    } else {
                        errorToast(json.message)
                    }
                } else {
                    errorToast(json.message)
                }


            },
            reTest() {
                this.oldEvalId = "";
                this.nowIndex = 1;
                this.current = 0;
                this.changeHeight();
                this.$nextTick(() => {
                    for (let i = 0; i < this.ipList2.length; i++) {
                        this.ipList2[i].selfDevNumber = 0;
                        this.ipList2[i].traGiftMergerNumber = 0
                    }
                    for (let i = 0; i < this.ipList1.length; i++) {
                        this.ipList1[i].selfDevNumber = 0;
                        this.ipList1[i].traGiftMergerNumber = 0
                    }
                    this.threeYearIpTotal = 0;
                    this.orgParticipation = 1;
                    this.relatedNumber = 0;
                    for (let item in this.fourthForm) {
                        if (item == 'evalId') {
                            this.fourthForm[item] = "";
                        } else {
                            this.fourthForm[item] = [];
                        }
                    }
                })

                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
                getEnterpriseInfo().then((res) => {
                    if (res.code == 200) {
                        this.baseForm.contactTel = res.data.contactTel;
                        this.baseForm.staffTotal = res.data.staffTotal;
                        this.baseForm.detailAddress = res.data.site;
                        this.baseForm.registerTime = res.data.registerTime ? new Date(res.data['registerTime']) : '';
                        this.baseForm.contactUser = res.data.legalRepresentative;
                        this.baseForm.creditCode = res.data.creditCode;
                        this.baseForm.enterpriseName = res.data.enterpriseName;
                        this.baseForm.provinceName = "";
                        this.baseForm.cityName = "";
                        this.baseForm.provinceCode = "";
                        this.baseForm.cityCode = "";
                        this.baseForm.punishment = 1;
                        this.researcherNumber = "";
                    }
                });
                getNewProvince().then((res) => {
                    if (res.code == 200) {
                        this.provinceData = res.data
                    }
                })
                this.getTechnology();
                let date = new Date();
                this.thisYear = date.getFullYear();
            },
            // async confirmFan() {
            //   this.financeFrom.evalId = this.evalId;
            //   let json = await confirmFinance(this.financeFrom);
            //   if (json.code == "200") {
            //     successToast("暂存成功");
            //     this.thirdFlag = true;
            //   } else {
            //     errorToast(json.message)
            //   }
            // },

            // async confirmIpBu() {
            //
            //
            // },

            // async confirmBaseBu() {
            //   for (let item in this.baseForm) {
            //     if (item !== "detailAddress" || item !== "awards") {
            //       if (this.baseForm[item] === "") {
            //         warnToast("请将数据填写完成");
            //         return;
            //       }
            //     }
            //   }
            //
            //   let json = await confirmBase(this.baseForm);
            //   if (json.code == "200") {
            //     this.evalId = json.data.evalId;
            //     successToast("暂存成功");
            //
            //   } else {
            //     errorToast(json.message)
            //   }
            // },

            async getTechnology() {
                let json = await getTechnologyField();
                if (json.code == "200") {
                    for (let i in json.data) {
                        let obj = {};
                        obj["name"] = json.data[i];
                        obj["value"] = i;
                        this.technologyList.push(obj);
                    }
                }
            },
            pre() {
                this.current -= 1;
            },
            getNewProvinceT(item) {
                console.log('item', item)
                this.baseForm.provinceName = this.provinceData[item]
                getByParentCode({parentCode: item}).then((res) => {
                    if (res.code == 200) {
                        this.cityData = res.data;
                    }
                })
            },
            getCity(item) {
                this.baseForm.cityName = this.cityData[item]
            },
            showTab(index) {
                this.$router.push({
                    path: this.tab_list[index].path
                })
                // this.changeHeight();
            },
            changeHeight() {
                let that = this;
                this.$nextTick(() => {
                    that.tab_height =
                        Number($(".right_content_parent").height()) -
                        (Number(document.body.clientHeight) - 101) >
                        20
                            ? $(".right_content_parent").height() + "px"
                            : Number(document.body.clientHeight) - 101 + "px";
                });
            },
        },
    };
</script>

<style scoped lang="scss">

    .yi_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }

    .table_div {
        height: 60px;
        line-height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #333333;
        padding-right: 19px;
    }

    .table_div_two {
        border-right: none !important;
        justify-content: flex-start;
    }

    .table_div2 {
        display: flex;
        align-items: center;
        height: 60px;
        color: #333333;
    }

    .step-bg {
        .table_div2 {
            display: inline-flex;
            width: auto;
        }
    }

    .arrow_ld {
        color: white !important;
        display: inline-block;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        line-height: 14px;
        text-align: center;
    }

    .div_first_td {
        color: white;
    }

    .table-box {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: left;
        border-bottom: 1px solid rgb(218, 226, 229);
        border-right: 1px solid rgb(218, 226, 229);
        padding: 0 10px;
        overflow: hidden;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .table-box2 {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: left;
        border-bottom: 1px solid rgb(218, 226, 229);
        border-right: 1px solid rgb(218, 226, 229);
        font-size: 14px;
    }

    .table-box-double {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: center;
        border-bottom: 1px solid rgb(218, 226, 229);
        border-right: 1px solid rgb(218, 226, 229);
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 10px;
    }

    .ivu-progress {
        display: inline-block !important;
        width: 65% !important;
    }

    .div_center {
        width: 95%;
        margin-left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted rgb(218, 226, 229);
    }

    .neirong {
        margin-left: 130px;

        .download_span {
            color: blue;
            display: inline-block;
            margin-right: 30px;
        }

        p {
            display: inline-block;
            margin-bottom: 20px;
            font-size: 14px;
            color: black;
        }
    }

    .bottom_style {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: -110px;
    }

    .common_liucheng:nth-child(1) {
        border: none;
    }

    .common_liucheng {
        border-top: 2px dotted #f5f5f5;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        & > span {
            display: block;
            padding-left: 5px;
            padding-top: 38px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            color: rgb(51, 51, 51);
            margin-bottom: 60px;
        }
    }

    .next_style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right_real_content {
        margin-bottom: 50px;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 40px;
        overflow: hidden;
    }

    .right_header {
        width: 95%;
        padding-top: 20px;
        height: 140px;
        position: relative;
        margin-bottom: 40px !important;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted #f5f5f5;
        overflow: hidden;

        span {
            display: block;
            margin-bottom: 20px;
        }
    }

    .button_style {
        display: inline-block;
        background-color: #1890FF;
        z-index: 51;
        text-align: center;
        font-size: 14px;
        color: white;
        cursor: pointer;
        padding: 0 27px;
        line-height: 32px;
        height: 32px;
        border-radius: 4px;
    }

    .right_content {
        min-width: 1220px;
        margin: 0 auto;
        /*border-width: 1px;*/
        /*border-color: rgb(218, 226, 229);*/
        /*border-style: solid;*/
        //   background-color: rgb(255, 255, 255);
        //   min-width: 900px;
        //   height: auto;
    }

    .right_content_parent {
        padding-top: 22px;
        margin-bottom: 130px;
        min-width: 1210px;
        position: relative;
        width: calc(100% - 160px);
        margin-left: 160px;
        min-height: calc(100% - 46px);
    }

    .left_tab_parent {
        height: 100%;
        min-width: 160px;
        width: 160px;
        background-color: #D5E7FA;
        position: fixed;
        top: 101px;

        .left_tab {
            div {
                height: 61px;
                line-height: 61px;
                font-size: 14px;
                color: #597697;
                padding-left: 10px;
                cursor: pointer;
                border-bottom: 1px solid #BDDBFA;

                &.isChoose {
                    color: #fff;
                    background-color: #1767E0;
                }
            }
        }
    }

    .hidden_span {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        white-space: pre-wrap;
        line-height: normal;
        margin-left: 10px;
        color: #333333;
    }

    .two-title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin: 0 auto;
        font-weight: bold;
    }

    .two-row {
        width: 500px;
        height: 16px;
        line-height: 16px;
        margin: 14px 0 14px 30%;
        display: inline-block;
    }

    .two-item {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #333333;
    }

    .step-title {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        padding: 0 0 40px 30px;
    }

    .step-con {
        background: #EAF2FB;
        padding: 20px 0;
        width: 1200px;
        margin: 0 auto;
        margin-top: 20px;
    }

    /deep/ .ivu-input {
        height: 40px !important;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding: 0 10px;
    }

    /deep/ .ivu-input-wrapper {
        width: 439px;
    }

    .page-1 {
        /deep/ .ivu-input-wrapper {
            width: 100px;
        }
    }

    /deep/ .ivu-select {
        // border-radius: 4px !important;
        // width: 188px;
    }

    .ivu-radio-wrapper {
        margin-right: 63px;
        color: #999999;
    }

    /deep/ .ivu-steps .ivu-steps-title,
    /deep/ .ivu-steps .ivu-steps-head{
        background: transparent;
    }

    /deep/ .ivu-steps-item {
        overflow: visible;

        .ivu-steps-main {
            display: block;
            margin-top: 20px;

            .ivu-steps-title {
                transform: translate(-50%);
                position: relative;
                left: 12px;
            }
        }

        .ivu-steps-tail {
            padding: 0px 14px;
        }

        &.ivu-steps-status-process, &.ivu-steps-status-finish {
            .ivu-steps-head {
                &:after {
                    border: 1px solid #2d8cf0;
                }
            }
        }

        &.ivu-steps-status-finish {
            .ivu-steps-title {
                color: #2d8cf0 !important;
            }
        }

        &.ivu-steps-status-wait {
            .ivu-steps-head-inner {
                background-color: #ccc;

                span {
                    color: #fff;
                }
            }
        }

        .ivu-steps-head {
            position: relative;
            padding-left: 0 !important;
            transform: scale(1.5);

            &:after {
                position: absolute;
                content: '';
                width: 30px;
                height: 30px;
                border: 1px solid #ccc;
                border-radius: 50%;
                display: inline-block;
                top: -2px;
                left: -2px;
            }
        }
    }
</style>
